import React from "react"
import { graphql } from "gatsby"
import DocLink from "../components/doclink"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import DoDont from '../components/dodont'
import TabLink from "../components/tablink"
import ListIcon from "../components/ListIcon"
import Icon from "../components/Icon"


const shortcodes = {
  DoDont,
  ListIcon,
  Icon
 }

function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

const docsFromCategory = (category, edges) => {
	  return edges
	  .filter(edge => edge.node.frontmatter.category === category && (edge.node.frontmatter.tab == null ||edge.node.frontmatter.tab.toLowerCase() === 'usage' ||edge.node.frontmatter.tab.toLowerCase() === 'overview'))
    .map(edge => <li><DocLink key={edge.node.id} doc={edge.node} /></li>);
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { blog } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = blog
  
  const { allBlogs } = data
  const { edges } = allBlogs
  const uniqueCategories = edges.map(edge => edge.node.frontmatter.category).filter(onlyUnique);
  const postsGroupedByCategory = uniqueCategories.map(category => <li>{category}<ul>{docsFromCategory(category, edges)}</ul></li>);
  //Get a list of tabs for current page
  const tabs = edges.filter(edge => edge.node.frontmatter.title === frontmatter.title);
  //Order the tabs (first is the default tab, then sort alphabetically)
  const sortedTabs = tabs.find(tab => tab.node.frontmatter.tab.toLowerCase() == 'usage' || tab.node.frontmatter.tab.toLowerCase() == 'overview')
  
  //Finally build the tabs that will be displayed
  const tabsForCurrentPage = tabs
								//Make the current tab not clickable and highlighted
								.map(edge => edge.node.frontmatter.tab !== frontmatter.tab ? <li><TabLink key={edge.node.id} doc={edge.node} /></li> : <li>{frontmatter.tab}</li>);
  
  return (
	<>
	<div style={{"height":"64px"}}>
		<h1>Documentation design system</h1>
	</div>
	<div style={{"width":"256px", "position":"absolute", "bottom":"0px", "top":"64px"}}><ul>{postsGroupedByCategory}</ul></div>
    <div className="doc-container" style={{"padding-left":"256px", "top":"64px"}}>
        <h1>{frontmatter.title}</h1>
		<div className="tabs-list">
			<ul>{tabsForCurrentPage}</ul>
		</div>
		<MDXProvider components={shortcodes}>
			<MDXRenderer>{body}</MDXRenderer>
		</MDXProvider>
    </div></>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    blog: mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        slug
        title
		category
		tab
      }
    }
	allBlogs: allMdx(sort: { order: ASC, fields: [frontmatter___category] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
			category
			tab
          }
        }
      }
    }
  }
`