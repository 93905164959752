import React from 'react';

import "../assets/style/less/dodont.css"

const DoDont = (props) => {
  const imgHeightCorrect = props.heightCorrect;
  const imgHeightFalse = props.heightFalse;
  const imgWidthFalse = props.widthFalse;
  const imgWidthCorrect = props.widthCorrect;
  const imgCorrect = props.imgCorrect;
  const imgFalse = props.imgFalse;

  return(
    <div>
      <div>
        <div className="imgDoDont" style={{marginRight: '30px'}}>
          <img className="imgCorrectCross" src="../assets/img/correct.png"/>
          <img style={{height: imgHeightCorrect, width: imgWidthCorrect }} className="imgDoCorrect" src={imgCorrect} />
        </div>
        <div className="imgDoDont">
          <img className="imgDoDontCross" src="../assets/img/dodont.png"/>
          <img style={{height: imgHeightFalse, width: imgWidthFalse }} className="imgDontCorrect" src={imgFalse} />
        </div>
      </div>
    </div>
  );
};

export default DoDont;
